import React from 'react';
import ContractInfo from "./ContractInfo";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {useLoaderData} from "react-router-dom";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import ContractLinesOfService from "./ContractLineOfService/ContractLinesOfService";
import ContractTerms from "./ContractTerms/ContractTerms";

const ContractDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query ContractDetailsQuery($id: ID!) {
            legalContract(id: $id) {
                id
                name
                startDate
                endDate
                network {
                    id
                    linesOfService {
                        ...LinesOfServiceFragments_LineOfServiceDropdown
                    }
                }
                ...ContractInfo_Contract
                linesOfService {
                    ...ContractLinesOfService_LinesOfService
                }
                terms {
                    ...ContractTerms_Terms
                }
            }
            allOrganizations {
                ...OrganizationsFragments_AllOrganizationsDropdown
            }
            allNetworks {
                ...NetworkFragments_allNetworkDropdown
            }
            allTermTypes {
                id
                description
                valueType
                ...TermTypesFragments_TermTypeDropdown
            }
        }
    `, loader.contractDetailsQuery)
    return (
        <>
            <DetailsPageHeader iconText={"Contract"} iconType={"pen-nib"} title={data.legalContract.name} />
            <ContractInfo contract={data.legalContract} networks={data.allNetworks} organizations={data.allOrganizations} />
            <ContractTerms terms={data.legalContract.terms} contractId={data.legalContract.id} possibleTermTypes={data.allTermTypes}/>
            <ContractLinesOfService linesOfService={data.legalContract.linesOfService}
                                    contractId={data.legalContract.id}
                                    possibleLinesOfService={data.legalContract.network.linesOfService}
                                    contractStartDate={data.legalContract.startDate}
                                    contractEndDate={data.legalContract.endDate}
            />
        </>
    );
};

export default ContractDetails;
