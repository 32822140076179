/**
 * @generated SignedSource<<004b89d77b84c18595ebb63347b569d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valueType",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Network",
            "kind": "LinkedField",
            "name": "network",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LineOfService",
                "kind": "LinkedField",
                "name": "linesOfService",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LinesOfServiceFragments_LineOfServiceDropdown"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContractInfo_Contract"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContractLineOfService",
            "kind": "LinkedField",
            "name": "linesOfService",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContractLinesOfService_LinesOfService"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContractTerm",
            "kind": "LinkedField",
            "name": "terms",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContractTerms_Terms"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "allOrganizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationsFragments_AllOrganizationsDropdown"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Network",
        "kind": "LinkedField",
        "name": "allNetworks",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NetworkFragments_allNetworkDropdown"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermType",
        "kind": "LinkedField",
        "name": "allTermTypes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TermTypesFragments_TermTypeDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Network",
            "kind": "LinkedField",
            "name": "network",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LineOfService",
                "kind": "LinkedField",
                "name": "linesOfService",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContractLineOfService",
            "kind": "LinkedField",
            "name": "linesOfService",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LineOfService",
                "kind": "LinkedField",
                "name": "lineOfService",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherServicesDiscountPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentageOfBase",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isComplex",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveFrom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveTo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContractTerm",
            "kind": "LinkedField",
            "name": "terms",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TermType",
                "kind": "LinkedField",
                "name": "type",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "allOrganizations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Network",
        "kind": "LinkedField",
        "name": "allNetworks",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermType",
        "kind": "LinkedField",
        "name": "allTermTypes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TermTypeCategory",
            "kind": "LinkedField",
            "name": "category",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fabec86822dcffde18b983044adced81",
    "id": null,
    "metadata": {},
    "name": "ContractDetailsQuery",
    "operationKind": "query",
    "text": "query ContractDetailsQuery(\n  $id: ID!\n) {\n  legalContract(id: $id) {\n    id\n    name\n    startDate\n    endDate\n    network {\n      id\n      linesOfService {\n        ...LinesOfServiceFragments_LineOfServiceDropdown\n        id\n      }\n    }\n    ...ContractInfo_Contract\n    linesOfService {\n      ...ContractLinesOfService_LinesOfService\n      id\n    }\n    terms {\n      ...ContractTerms_Terms\n      id\n    }\n  }\n  allOrganizations {\n    ...OrganizationsFragments_AllOrganizationsDropdown\n    id\n  }\n  allNetworks {\n    ...NetworkFragments_allNetworkDropdown\n    id\n  }\n  allTermTypes {\n    id\n    description\n    valueType\n    ...TermTypesFragments_TermTypeDropdown\n  }\n}\n\nfragment ContractInfo_Contract on LegalContract {\n  id\n  name\n  startDate\n  endDate\n  network {\n    name\n    id\n  }\n  organization {\n    primaryName\n    id\n  }\n}\n\nfragment ContractLinesOfService_LinesOfService on LegalContractLineOfService {\n  id\n  lineOfService {\n    id\n    name\n  }\n  otherServicesDiscountPercentage\n  percentageOfBase\n  isComplex\n  effectiveFrom\n  effectiveTo\n}\n\nfragment ContractTerms_Terms on LegalContractTerm {\n  id\n  type {\n    id\n    description\n    valueType\n  }\n  value\n}\n\nfragment LinesOfServiceFragments_LineOfServiceDropdown on LineOfService {\n  id\n  name\n}\n\nfragment NetworkFragments_allNetworkDropdown on Network {\n  id\n  name\n}\n\nfragment OrganizationsFragments_AllOrganizationsDropdown on Organization {\n  id\n  primaryName\n}\n\nfragment TermTypesFragments_TermTypeDropdown on TermType {\n  id\n  description\n  displayOrder\n  category {\n    displayOrder\n    id\n  }\n}\n"
  }
};
})();

node.hash = "278970369ffd95262881a46fca649e7c";

export default node;
