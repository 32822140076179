import graphql from "babel-plugin-relay/macro";

export const allIdentifierTypesDropdown = graphql`
    fragment Fragments_AllIdentifierTypes on IdentifierType @relay(plural: true) {
        id,
        display
    }`

export const allIdentifierUseCodesDropdown = graphql`
    fragment Fragments_AllIdentifierUseCodes on IdentifierUseCode @relay(plural:true) {
        id,
        display
    }`

export const allContactPointSystemCodesDropdown = graphql`
    fragment Fragments_AllContactPointSystemCodesDropdown on ContactPointSystemCode @relay(plural: true) {
        id,
        display
    }`

export const allContactPointUseCodesDropdown = graphql`
    fragment Fragments_AllContactPointUseCodesDropdown on ContactPointUseCode @relay(plural: true) {
        id,
        display
    }`

export const allAddressUseCodesDropdown = graphql`
    fragment Fragments_AllAddressUseCodesDropdown on AddressUseCode @relay(plural: true) {
        id,
        display
    }`